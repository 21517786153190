import * as React from 'react'
import check from '../images/check.svg'

export default function ({children}) {
    return <div className="flex items-center my-4">
    <div className="mr-2">
      <img src={check} />
    </div>
    <div className="mt-1">
      {children}
    </div>
  </div>
}