import * as React from "react";

import hero from "../images/hero.png";
import search from "../images/search.png";
import survey from "../images/survey.png";
import resources from "../images/resources.png";
import transactions from "../images/transactions.png";

import Layout from "../components/layouts/DefaultLayout";
import Feature from "../components/Feature";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <div className="max-w-7xl mx-auto my-8">
        <div className="flex justify-between my-32">
          <div className="max-w-xl">
            <h1 className="font-bold text-5xl mt-3">
              Let's Help People Thrive.
            </h1>
            <h2 className="font-light text-4xl mt-2 mb-8">
              We spread information.
            </h2>

            <p className="leading-7">
              The Nexus 211 platform offers a suite of building blocks that make
              it easier to use modern technology to create advanced information
              and referral (I&amp;R) applications.
            </p>

            <div className="-mx-3 my-8">
              <a
                href="mailto:info@nexus211.com"
                className="mx-3 border border-primary-500 font-bold text-primary-500 px-4 py-2 rounded-sm hover:opacity-75"
              >
                Contact Us
              </a>
              <a
                href="/docs"
                className="mx-3 border border-primary-500 bg-primary-500 font-bold text-white px-4 py-2 rounded-sm hover:opacity-75"
              >
                Read the Docs
              </a>
            </div>
          </div>
          <div>
            <img src={hero} width="480" />
          </div>
        </div>

        <div className="my-12">
          <div className="text-center">
            <h3 className="text-3xl mb-4">Solutions made for you</h3>
            <p className="max-w-2xl mx-auto leading-7 my-4">
              The Nexus211 platform enables tools and services built around a
              common core, reducing redundancy, eliminating inefficiency, and
              speeding the development process.
            </p>
          </div>

          <div className="flex -mx-12 my-32 items-center">
            <div className="flex-1 mx-12">
              <h3 className="text-2xl mb-4">Transactions</h3>
              <p>Capture the full story behind every engagement.</p>

              <Feature>
                Capture as much as information you want per transaction.
              </Feature>
              <Feature>Initiate referrals and surveys.</Feature>
              <Feature>View past interactions with people.</Feature>
              <Feature>
                Give your contacts access to their transaction history.
              </Feature>
            </div>
            <div className="flex-1 mx-12">
              <img src={transactions} width="500" className="inline-block" />
            </div>
          </div>

          <div className="flex -mx-12 my-32 items-center">
            <div className="flex-1 mx-12">
              <img src={resources} width="500" className="inline-block" />
            </div>
            <div className="flex-1 mx-12">
              <h3 className="text-2xl mb-4">Resources</h3>
              <p>AIRS-compatible resource definition and management.</p>

              <Feature>AIRS-compatible resource definition</Feature>
              <Feature>Compartmentalized collections</Feature>
              <Feature>Role-based access control</Feature>
              <Feature>Sharable via networks</Feature>
            </div>
          </div>

          <div className="flex -mx-12 my-32 items-center">
            <div className="flex-1 mx-12">
              <h3 className="text-2xl mb-4">Search</h3>
              <p>Fast and accurate realtime resource search.</p>

              <Feature>Comprehensive resource search</Feature>
              <Feature>Keyword and faceted search modes</Feature>
              <Feature>Location eligibility and proximity filters</Feature>
              <Feature>Network-aware</Feature>
            </div>
            <div className="flex-1 mx-12">
              <img src={search} width="500" className="inline-block" />
            </div>
          </div>

          <div className="flex -mx-12 my-32 items-center">
            <div className="flex-1 mx-12">
              <img src={survey} width="500" className="inline-block" />
            </div>
            <div className="flex-1 mx-12">
              <h3 className="text-2xl mb-4">Surveys</h3>
              <p>Create surveys and collect information.</p>

              <Feature>Use surveys internally, or publicly</Feature>
              <Feature>Can be translated into multiple languages</Feature>
              <Feature>Supports skip logic, calculations and more</Feature>
              <Feature>Powered by xforms and Enketo</Feature>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-pattern w-full text-center py-32">
        <h3 class="text-3xl text-white font-bold my-8">Join Nexus211 Today</h3>
        <a
          href="mailto:info@nexus211.com"
          className="mx-3 border border-white bg-white font-bold text-gray-800 px-4 py-2 rounded-sm hover:opacity-75"
        >
          Contact Us
        </a>
        <a
          href="/docs"
          className="mx-3 border border-primary-500 bg-primary-500 font-bold text-white px-4 py-2 rounded-sm hover:opacity-75"
        >
          Learn More
        </a>
      </div>
    </Layout>
  );
};

export default IndexPage;
