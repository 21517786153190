import React from "react";
import logo from "../images/logo.svg";

export default function Footer({ hideLogo }) {
  return (
    <footer className="max-w-7xl mx-auto my-8">
      <div className="grid grid-cols-4 gap-4">
        <div>
          <img src={logo} width="120" className="mb-3" />
          <div className="text-gray-800 text-sm">
            Information and Referral Federation of Los Angeles County, Inc.
          </div>
        </div>
        <div>
          <h4 className="text-lg mb-2">Home</h4>
          <ul className="text-sm leading-10">
            <li>
              <a href="">Features</a>
            </li>
            <li>
              <a href="">Resources</a>
            </li>
            <li>
              <a href="">Surveys</a>
            </li>
            <li>
              <a href="">Transactions</a>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="text-lg mb-2">Developers</h4>
          <ul className="text-sm leading-10">
            <li>
              <a href="/docs/glossary">Glossary</a>
            </li>
            <li>
              <a href="/docs">Documentation</a>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="text-lg mb-2">ID</h4>
          <ul className="text-sm leading-10">
            <li>
              <a href="https://id.nexus211.com/sign-in">Log in</a>
            </li>
            <li>
              <a href="https://id.nexus211.com/sign-up">Sign Up</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  hideLogo: false,
};
