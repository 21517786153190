import React from "react";

import logo from "../../images/logo.svg";
import Footer from "../Footer";

export default function DefaultLayout({ children, ...props }) {
  return (
    <div className="bg-gradient">
      <nav className="max-w-7xl mx-auto pt-4 mb-2">
        <div className="flex items-center">
          <div>
            <a href="/">
              <img src={logo} width="162" className="block" />
            </a>
          </div>
          <ul className="flex-1 text-right pt-5">
            <li>
              <a href="#" className="text-blue-500 mx-4 hover:opacity-75">
                Features
              </a>
              <a href="/docs" className="text-blue-500 mx-4 hover:opacity-75">
                Docs
              </a>
              <a
                href="https://id.nexus211.com/sign-in"
                className="text-blue-500 mx-4 font-bold hover:opacity-75"
              >
                Log In
              </a>
              <a
                href="https://id.nexus211.com/sign-up"
                className="bg-primary-500 mx-4 font-bold text-white px-4 py-2 rounded-sm hover:opacity-75"
              >
                Sign Up
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <main>{children}</main>

      <Footer />
    </div>
  );
}
